var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { style: _vm.backCss },
    [
      _c(
        "div",
        { staticClass: "statistics-menu-list" },
        _vm._l(_vm.columns, function (item, index) {
          return _c(
            "a-dropdown",
            {
              key: index,
              ref: "statistics-" + index,
              refInFor: true,
              style: item.isNum ? "" : "display: none;",
              attrs: { trigger: ["click"], placement: "topCenter" },
            },
            [
              _c(
                "div",
                { staticClass: "statistics" },
                [_vm._v("统计"), _c("a-icon", { attrs: { type: "caret-up" } })],
                1
              ),
              _c(
                "a-menu",
                { attrs: { slot: "overlay" }, slot: "overlay" },
                [
                  item.isNum
                    ? _c(
                        "a-menu-item",
                        {
                          on: {
                            click: function ($event) {
                              return _vm.compute(
                                "meanNum",
                                item.dataIndex,
                                index,
                                "求和"
                              )
                            },
                          },
                        },
                        [_vm._v("\n          求和\n        ")]
                      )
                    : _vm._e(),
                  item.isNum
                    ? _c(
                        "a-menu-item",
                        {
                          on: {
                            click: function ($event) {
                              return _vm.compute(
                                "sumNum",
                                item.dataIndex,
                                index,
                                "平均"
                              )
                            },
                          },
                        },
                        [_vm._v("\n          平均\n        ")]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          )
        }),
        1
      ),
      _c(
        "a-spin",
        { attrs: { spinning: _vm.tableLoading } },
        [
          _c(
            "a-dropdown",
            {
              attrs: { placement: "bottomRight", trigger: ["contextmenu"] },
              model: {
                value: _vm.visible,
                callback: function ($$v) {
                  _vm.visible = $$v
                },
                expression: "visible",
              },
            },
            [
              _c(
                "a-menu",
                { attrs: { slot: "overlay" }, slot: "overlay" },
                _vm._l(_vm.columns, function (item, index) {
                  return _c(
                    "a-menu-item",
                    { key: item.dataIndex },
                    [
                      _c(
                        "a-checkbox",
                        {
                          on: {
                            change: function ($event) {
                              return _vm.checkChange(index)
                            },
                          },
                          model: {
                            value: item.checked,
                            callback: function ($$v) {
                              _vm.$set(item, "checked", $$v)
                            },
                            expression: "item.checked",
                          },
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(item.title) +
                              "\n          "
                          ),
                        ]
                      ),
                    ],
                    1
                  )
                }),
                1
              ),
              _c(
                "vxe-table",
                {
                  ref: "xTree",
                  attrs: {
                    border: "none",
                    "row-config": { isCurrent: true, isHover: true },
                    "column-config": { resizable: true },
                    "footer-method": _vm.footerMethod,
                    "tree-config": {
                      hasChild: "hasChild",
                      parentField: "parentId",
                      rowField: "teamId",
                      lazy: true,
                      loadMethod: _vm.loadChildrenMethod,
                    },
                    "filter-config": {
                      remote: true,
                      iconNone: "iconfont icon-shaixuan icon",
                      iconMatch:
                        "iconfont icon-shaixuan icon filter-product-btn",
                    },
                    data: _vm.tableData,
                    "show-footer": "",
                  },
                  on: {
                    "checkbox-change": _vm.selectChangeEvent,
                    "checkbox-all": _vm.selectChangeEvent,
                    "cell-click": _vm.cellClickEvent,
                    "toggle-tree-expand": _vm.toggleExpandChangeEvent,
                  },
                },
                [
                  _c("vxe-column", {
                    attrs: {
                      align: "center",
                      fixed: "left",
                      type: "checkbox",
                      minWidth: "80",
                      "show-overflow": "",
                    },
                  }),
                  _vm._l(_vm.copyColumns, function (record) {
                    return _c("vxe-column", {
                      key: record.dataIndex,
                      attrs: {
                        fixed: record.dataIndex == "team" ? "left" : "",
                        field: record.dataIndex,
                        title: record.title,
                        minWidth: record.width,
                        "tree-node": record.dataIndex == "team",
                        "show-overflow": "",
                      },
                      scopedSlots: _vm._u(
                        [
                          record.dataIndex == "team"
                            ? {
                                key: "default",
                                fn: function ({ row }) {
                                  return [
                                    !row.hasChild
                                      ? _c(
                                          "div",
                                          {
                                            staticClass: "nick-name-div",
                                            staticStyle: {
                                              display: "flex",
                                              "align-items": "center",
                                            },
                                            on: {
                                              click: function ($event) {
                                                $event.stopPropagation()
                                                return _vm.toOtherCalender(row)
                                              },
                                            },
                                          },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "status-img" },
                                              [
                                                row.userFile.avatar
                                                  ? _c("img", {
                                                      staticStyle: {
                                                        width: "35px",
                                                        height: "35px",
                                                        "border-radius": "50%",
                                                      },
                                                      attrs: {
                                                        src: row.userFile
                                                          .avatar,
                                                        alt: "",
                                                      },
                                                    })
                                                  : _c("img", {
                                                      staticStyle: {
                                                        width: "35px",
                                                        height: "35px",
                                                        "border-radius": "50%",
                                                      },
                                                      attrs: {
                                                        src: require("./../../assets/tx.png"),
                                                        alt: "",
                                                      },
                                                    }),
                                                _c("div", {
                                                  staticClass: "status",
                                                  style:
                                                    row.onlineType == 1
                                                      ? "background-color:#24B300"
                                                      : row.onlineType == 2
                                                      ? "background-color:gray"
                                                      : "background-color:#ADADAD",
                                                }),
                                              ]
                                            ),
                                            _c("span", [
                                              _vm._v(_vm._s(row.nickName)),
                                            ]),
                                          ]
                                        )
                                      : _c("div", [
                                          _vm._v(
                                            "\n              " +
                                              _vm._s(
                                                row.team || row.standbyTeam
                                              ) +
                                              "\n              "
                                          ),
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                row.childs && row.childs.length
                                                  ? "（" +
                                                      row.childs.length +
                                                      "）"
                                                  : ""
                                              )
                                            ),
                                          ]),
                                        ]),
                                  ]
                                },
                              }
                            : record.dataIndex == "userFile.workDays"
                            ? {
                                key: "default",
                                fn: function ({ row }) {
                                  return [
                                    !row.hasChild
                                      ? _vm._l(
                                          Array.from(
                                            row.userFile.workDays || []
                                          ).sort((a, b) => {
                                            return a - b
                                          }),
                                          function (one) {
                                            return _c("a-tag", { key: one }, [
                                              _vm._v(
                                                "\n                " +
                                                  _vm._s(
                                                    one == "1"
                                                      ? "周一"
                                                      : one == "2"
                                                      ? "周二"
                                                      : one == "3"
                                                      ? "周三"
                                                      : one == "4"
                                                      ? "周四"
                                                      : one == "5"
                                                      ? "周五"
                                                      : one == "6"
                                                      ? "周六"
                                                      : one == "7"
                                                      ? "周日"
                                                      : one == "-1"
                                                      ? "跳过中国法定节假日"
                                                      : ""
                                                  ) +
                                                  "\n              "
                                              ),
                                            ])
                                          }
                                        )
                                      : _vm._e(),
                                  ]
                                },
                              }
                            : record.dataIndex == "userFile.qq"
                            ? {
                                key: "default",
                                fn: function ({ row }) {
                                  return [
                                    !row.hasChild
                                      ? _c("span", [
                                          row.userFile.qq
                                            ? _c(
                                                "div",
                                                [
                                                  _c("a-icon", {
                                                    staticStyle: {
                                                      width: "1vw",
                                                      color: "hsl(0deg 0% 75%)",
                                                    },
                                                    attrs: { type: "qq" },
                                                  }),
                                                  _c(
                                                    "span",
                                                    {
                                                      staticStyle: {
                                                        "margin-left": "3px",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(row.userFile.qq)
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          row.userFile.qqType
                                            ? _c(
                                                "div",
                                                [
                                                  _c("a-icon", {
                                                    staticStyle: {
                                                      width: "1vw",
                                                      color: "hsl(0deg 0% 75%)",
                                                    },
                                                    attrs: { type: "wechat" },
                                                  }),
                                                  _c(
                                                    "span",
                                                    {
                                                      staticStyle: {
                                                        "margin-left": "3px",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          row.userFile.qqType
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                        ])
                                      : _vm._e(),
                                  ]
                                },
                              }
                            : record.dataIndex ==
                              "userFile.goodAtProductionType"
                            ? {
                                key: "default",
                                fn: function ({ row }) {
                                  return [
                                    _c(
                                      "span",
                                      [
                                        !row.hasChild &&
                                        row.userFile.goodAtProductionType
                                          ? _vm._l(
                                              row.userFile.goodAtProductionType.split(
                                                ","
                                              ),
                                              function (one) {
                                                return _c(
                                                  "a-tag",
                                                  { key: one },
                                                  [
                                                    _vm._v(
                                                      "\n                  " +
                                                        _vm._s(
                                                          one == "其他"
                                                            ? row.userFile
                                                                .skilledProductOther
                                                              ? row.userFile
                                                                  .skilledProductOther
                                                              : one
                                                            : one
                                                        ) +
                                                        "\n                "
                                                    ),
                                                  ]
                                                )
                                              }
                                            )
                                          : _vm._e(),
                                      ],
                                      2
                                    ),
                                  ]
                                },
                              }
                            : record.dataIndex == "userFile.badAtProductionType"
                            ? {
                                key: "default",
                                fn: function ({ row }) {
                                  return [
                                    _c(
                                      "span",
                                      [
                                        !row.hasChild &&
                                        row.userFile.badAtProductionType
                                          ? _vm._l(
                                              row.userFile.badAtProductionType.split(
                                                ","
                                              ),
                                              function (one) {
                                                return _c(
                                                  "a-tag",
                                                  { key: one },
                                                  [
                                                    _vm._v(
                                                      "\n                  " +
                                                        _vm._s(
                                                          one == "其他"
                                                            ? row.userFile
                                                                .unskilledProductOther
                                                              ? row.userFile
                                                                  .unskilledProductOther
                                                              : one
                                                            : one
                                                        ) +
                                                        "\n                "
                                                    ),
                                                  ]
                                                )
                                              }
                                            )
                                          : _vm._e(),
                                      ],
                                      2
                                    ),
                                  ]
                                },
                              }
                            : record.dataIndex == "weekAvailableCount"
                            ? {
                                key: "default",
                                fn: function ({ row }) {
                                  return [
                                    _vm._v(
                                      _vm._s(!row.hasChild ? row.weekTotal : "")
                                    ),
                                  ]
                                },
                              }
                            : record.dataIndex == "userFile.workCity"
                            ? {
                                key: "default",
                                fn: function ({ row }) {
                                  return [
                                    _c("span", [
                                      _c("div", [
                                        _vm._v(
                                          _vm._s(
                                            !row.hasChild
                                              ? _vm.getWorkCity(
                                                  row.userFile.workCity
                                                )
                                              : ""
                                          )
                                        ),
                                      ]),
                                    ]),
                                  ]
                                },
                              }
                            : record.dataIndex == "userFile.weekFreeCount"
                            ? {
                                key: "default",
                                fn: function ({ row }) {
                                  return [
                                    _vm._v(_vm._s(row.weekCanScheduleCount)),
                                  ]
                                },
                              }
                            : record.dataIndex == "weekTaskCount"
                            ? {
                                key: "default",
                                fn: function ({ row }) {
                                  return [_vm._v(_vm._s(row.weekTaskCount))]
                                },
                              }
                            : record.dataIndex == "enabled"
                            ? {
                                key: "default",
                                fn: function ({ row }) {
                                  return [
                                    !row.hasChild
                                      ? _c(
                                          "div",
                                          [
                                            row.enabled
                                              ? _c("a-icon", {
                                                  attrs: {
                                                    type: "close-circle",
                                                  },
                                                })
                                              : _c("a-icon", {
                                                  attrs: {
                                                    type: "check-circle",
                                                  },
                                                }),
                                            _c(
                                              "span",
                                              {
                                                staticStyle: {
                                                  "margin-left": "4px",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    row.enabled
                                                      ? "停用"
                                                      : "启用"
                                                  )
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                  ]
                                },
                              }
                            : null,
                        ],
                        null,
                        true
                      ),
                    })
                  }),
                ],
                2
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }