var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { class: ["edit-assess-form"] },
    [
      _c(
        "div",
        { staticClass: "assess-form-item" },
        [
          _c(
            "a-row",
            {
              staticClass: "title-row",
              attrs: {
                type: "flex",
                align: "middle",
                gutter: _vm.complete.gutter,
              },
            },
            [
              _c("a-col", _vm._b({}, "a-col", _vm.complete.col[0], false), [
                _vm._v("公式"),
              ]),
              _c(
                "a-col",
                _vm._b(
                  { staticClass: "gutter-row", attrs: { flex: "1" } },
                  "a-col",
                  _vm.complete.col[1],
                  false
                ),
                [
                  _vm.isEdit
                    ? [
                        _c("a-textarea", {
                          attrs: { "auto-size": "" },
                          model: {
                            value: _vm.formData.formula,
                            callback: function ($$v) {
                              _vm.$set(_vm.formData, "formula", $$v)
                            },
                            expression: "formData.formula",
                          },
                        }),
                      ]
                    : _c("span", { staticClass: "formula" }, [
                        _vm._v(_vm._s(_vm.formData.formula)),
                      ]),
                ],
                2
              ),
              _c("a-col", _vm._b({}, "a-col", _vm.modify.col[3], false)),
            ],
            1
          ),
        ],
        1
      ),
      _vm.haveSelect(_vm.formData)
        ? [
            _vm.formData.kpiDeliveries
              ? _c(
                  "div",
                  { staticClass: "assess-form-item" },
                  [
                    _c(
                      "a-row",
                      {
                        staticClass: "title-row",
                        attrs: {
                          type: "flex",
                          align: "middle",
                          gutter: _vm.complete.gutter,
                        },
                      },
                      [
                        _c(
                          "a-col",
                          _vm._b({}, "a-col", _vm.complete.col[0], false),
                          [_vm._v("完成情况")]
                        ),
                        _c(
                          "a-col",
                          _vm._b(
                            { staticClass: "gutter-row" },
                            "a-col",
                            _vm.complete.col[1],
                            false
                          ),
                          [
                            _c("div", { staticClass: "gutter-box" }, [
                              _vm._v("分数"),
                            ]),
                          ]
                        ),
                        _vm.isEdit
                          ? _c(
                              "a-col",
                              _vm._b(
                                { staticClass: "gutter-row" },
                                "a-col",
                                _vm.complete.col[2],
                                false
                              ),
                              [
                                _c(
                                  "div",
                                  { staticClass: "row-option" },
                                  [
                                    _c("a-button", {
                                      attrs: {
                                        shape: "circle",
                                        icon: "close",
                                        size: "small",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.delAssess(
                                            "kpiDeliveries",
                                            true
                                          )
                                        },
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                    _vm._l(_vm.formData.kpiDeliveries, function (row, r_idx) {
                      return _c(
                        "a-row",
                        {
                          key: r_idx,
                          attrs: {
                            type: "flex",
                            align: "middle",
                            gutter: _vm.complete.gutter,
                          },
                        },
                        [
                          _c(
                            "a-col",
                            _vm._b(
                              { staticClass: "gutter-row" },
                              "a-col",
                              _vm.complete.col[0],
                              false
                            ),
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.getLabel(
                                    _vm.deliveriesTypeList,
                                    row.status
                                  )
                                ) + "\n        "
                              ),
                            ]
                          ),
                          _c(
                            "a-col",
                            _vm._b(
                              { staticClass: "gutter-row" },
                              "a-col",
                              _vm.complete.col[1],
                              false
                            ),
                            [
                              _vm.isEdit
                                ? [
                                    _c("input-number", {
                                      attrs: {
                                        formData: row,
                                        valueKey: "score",
                                      },
                                    }),
                                  ]
                                : [_vm._v(_vm._s(row.score))],
                            ],
                            2
                          ),
                        ],
                        1
                      )
                    }),
                  ],
                  2
                )
              : _vm._e(),
            _vm.errorList.length
              ? _c(
                  "div",
                  { staticClass: "assess-form-item" },
                  [
                    _c(
                      "a-row",
                      {
                        staticClass: "title-row",
                        attrs: {
                          type: "flex",
                          align: "middle",
                          gutter: _vm.error.gutter,
                        },
                      },
                      [
                        _c(
                          "a-col",
                          _vm._b({}, "a-col", _vm.error.col[0], false),
                          [_vm._v("错翻")]
                        ),
                        _c(
                          "a-col",
                          _vm._b(
                            { staticClass: "gutter-row" },
                            "a-col",
                            _vm.error.col[1],
                            false
                          ),
                          [
                            _c("div", { staticClass: "gutter-box" }, [
                              _vm._v("单个扣分分值"),
                            ]),
                          ]
                        ),
                        _c(
                          "a-col",
                          _vm._b(
                            { staticClass: "gutter-row" },
                            "a-col",
                            _vm.error.col[2],
                            false
                          ),
                          [
                            _c("div", { staticClass: "gutter-box" }, [
                              _vm._v("满分"),
                            ]),
                          ]
                        ),
                        _vm.isEdit
                          ? _c(
                              "a-col",
                              _vm._b(
                                { staticClass: "gutter-row" },
                                "a-col",
                                _vm.error.col[3],
                                false
                              ),
                              [
                                _c(
                                  "div",
                                  { staticClass: "row-option" },
                                  [
                                    _c("a-button", {
                                      attrs: {
                                        shape: "circle",
                                        size: "small",
                                        icon: "close",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.delAssess("errorList")
                                        },
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                    _c("edit-error-form", {
                      attrs: {
                        isEdit: _vm.isEdit,
                        col: _vm.error.col,
                        gutter: _vm.error.gutter,
                        formData: _vm.formData,
                        needOption: false,
                        formList: _vm.errorList,
                        maxScoreKey: "errorMaxScore",
                        errorTypeList: _vm.errorTypeTree,
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm.hardInjuryList.length
              ? _c(
                  "div",
                  { staticClass: "assess-form-item" },
                  [
                    _c(
                      "a-row",
                      {
                        staticClass: "title-row",
                        attrs: {
                          type: "flex",
                          align: "middle",
                          gutter: _vm.error.gutter,
                        },
                      },
                      [
                        _c(
                          "a-col",
                          _vm._b({}, "a-col", _vm.error.col[0], false),
                          [_vm._v("硬伤")]
                        ),
                        _c(
                          "a-col",
                          _vm._b(
                            { staticClass: "gutter-row" },
                            "a-col",
                            _vm.error.col[1],
                            false
                          ),
                          [
                            _c("div", { staticClass: "gutter-box" }, [
                              _vm._v("单个扣分分值"),
                            ]),
                          ]
                        ),
                        _c(
                          "a-col",
                          _vm._b(
                            { staticClass: "gutter-row" },
                            "a-col",
                            _vm.error.col[2],
                            false
                          ),
                          [
                            _c("div", { staticClass: "gutter-box" }, [
                              _vm._v("满分"),
                            ]),
                          ]
                        ),
                        _vm.isEdit
                          ? _c(
                              "a-col",
                              _vm._b(
                                { staticClass: "gutter-row" },
                                "a-col",
                                _vm.error.col[3],
                                false
                              ),
                              [
                                _c(
                                  "div",
                                  { staticClass: "row-option" },
                                  [
                                    _c("a-button", {
                                      attrs: {
                                        shape: "circle",
                                        size: "small",
                                        icon: "close",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.delAssess("hardInjuryList")
                                        },
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                    _c("edit-error-form", {
                      attrs: {
                        isEdit: _vm.isEdit,
                        col: _vm.error.col,
                        gutter: _vm.error.gutter,
                        formData: _vm.formData,
                        formList: _vm.hardInjuryList,
                        maxScoreKey: "hardMaxScore",
                        errorTypeList: _vm.hardInjuryTypeTree,
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm.softInjuryList.length
              ? _c(
                  "div",
                  { staticClass: "assess-form-item" },
                  [
                    _c(
                      "a-row",
                      {
                        staticClass: "title-row",
                        attrs: {
                          type: "flex",
                          align: "middle",
                          gutter: _vm.error.gutter,
                        },
                      },
                      [
                        _c(
                          "a-col",
                          _vm._b({}, "a-col", _vm.error.col[0], false),
                          [_vm._v("软伤")]
                        ),
                        _c(
                          "a-col",
                          _vm._b(
                            { staticClass: "gutter-row" },
                            "a-col",
                            _vm.error.col[1],
                            false
                          ),
                          [
                            _c("div", { staticClass: "gutter-box" }, [
                              _vm._v("单个扣分分值"),
                            ]),
                          ]
                        ),
                        _c(
                          "a-col",
                          _vm._b(
                            { staticClass: "gutter-row" },
                            "a-col",
                            _vm.error.col[2],
                            false
                          ),
                          [
                            _c("div", { staticClass: "gutter-box" }, [
                              _vm._v("满分"),
                            ]),
                          ]
                        ),
                        _vm.isEdit
                          ? _c(
                              "a-col",
                              _vm._b(
                                { staticClass: "gutter-row" },
                                "a-col",
                                _vm.error.col[3],
                                false
                              ),
                              [
                                _c(
                                  "div",
                                  { staticClass: "row-option" },
                                  [
                                    _c("a-button", {
                                      attrs: {
                                        shape: "circle",
                                        size: "small",
                                        icon: "close",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.delAssess("softInjuryList")
                                        },
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                    _c("edit-error-form", {
                      attrs: {
                        isEdit: _vm.isEdit,
                        col: _vm.error.col,
                        gutter: _vm.error.gutter,
                        formData: _vm.formData,
                        formList: _vm.softInjuryList,
                        maxScoreKey: "softMaxScore",
                        errorTypeList: _vm.softInjuryTypeTree,
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm.formData.kpiModifies
              ? _c(
                  "div",
                  { staticClass: "assess-form-item" },
                  [
                    _c(
                      "a-row",
                      {
                        staticClass: "title-row",
                        attrs: {
                          type: "flex",
                          align: "middle",
                          gutter: _vm.modify.gutter,
                        },
                      },
                      [
                        _c(
                          "a-col",
                          _vm._b({}, "a-col", _vm.modify.col[0], false),
                          [_vm._v("修改率")]
                        ),
                        _c(
                          "a-col",
                          _vm._b(
                            { staticClass: "gutter-row" },
                            "a-col",
                            _vm.modify.col[2],
                            false
                          ),
                          [
                            _c("div", { staticClass: "gutter-box" }, [
                              _vm._v("范围(%)"),
                            ]),
                          ]
                        ),
                        _c(
                          "a-col",
                          _vm._b(
                            { staticClass: "gutter-row" },
                            "a-col",
                            _vm.modify.col[1],
                            false
                          ),
                          [
                            _c("div", { staticClass: "gutter-box" }, [
                              _vm._v("分数"),
                            ]),
                          ]
                        ),
                        _vm.isEdit
                          ? _c(
                              "a-col",
                              _vm._b(
                                { staticClass: "gutter-row" },
                                "a-col",
                                _vm.modify.col[3],
                                false
                              ),
                              [
                                _c(
                                  "div",
                                  { staticClass: "row-option" },
                                  [
                                    _c("a-button", {
                                      attrs: {
                                        shape: "circle",
                                        icon: "close",
                                        size: "small",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.delAssess(
                                            "kpiModifies",
                                            true
                                          )
                                        },
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                    _vm._l(_vm.formData.kpiModifies, function (row, r_idx) {
                      return _c(
                        "a-row",
                        {
                          key: r_idx,
                          attrs: {
                            type: "flex",
                            align: "middle",
                            gutter: _vm.modify.gutter,
                          },
                        },
                        [
                          _c(
                            "a-col",
                            _vm._b(
                              { staticClass: "gutter-row" },
                              "a-col",
                              _vm.modify.col[0],
                              false
                            ),
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.getLabel(_vm.modifiesTypeList, row.type)
                                )
                              ),
                            ]
                          ),
                          _c(
                            "a-col",
                            _vm._b(
                              { staticClass: "gutter-row" },
                              "a-col",
                              _vm.modify.col[2],
                              false
                            ),
                            [
                              _vm.isEdit
                                ? [
                                    _c("edit-range-select", {
                                      attrs: {
                                        formData: row.range,
                                        rangeKey: "type",
                                        valueKey: "value",
                                        needUnit: false,
                                        suffix: "%",
                                      },
                                    }),
                                  ]
                                : [
                                    _c("edit-range-select", {
                                      attrs: {
                                        suffix: "%",
                                        rangeObj: row.range,
                                      },
                                    }),
                                  ],
                            ],
                            2
                          ),
                          _c(
                            "a-col",
                            _vm._b(
                              { staticClass: "gutter-row" },
                              "a-col",
                              _vm.modify.col[1],
                              false
                            ),
                            [
                              _vm.isEdit
                                ? [
                                    _c("input-number", {
                                      attrs: {
                                        formData: row,
                                        valueKey: "score",
                                      },
                                    }),
                                  ]
                                : [_vm._v(_vm._s(row.score))],
                            ],
                            2
                          ),
                        ],
                        1
                      )
                    }),
                  ],
                  2
                )
              : _vm._e(),
          ]
        : _vm._e(),
      _vm.isEdit
        ? _c(
            "div",
            { staticClass: "assess-form-item" },
            [
              _c(
                "a-row",
                {
                  staticClass: "title-row",
                  attrs: {
                    type: "flex",
                    align: "middle",
                    gutter: _vm.complete.gutter,
                  },
                },
                [
                  _c(
                    "a-col",
                    [
                      _c(
                        "a-select",
                        {
                          staticClass: "only-select",
                          attrs: {
                            placeholder: "请选择新增项",
                            value: "请选择新增项",
                          },
                          on: { change: _vm.changeAssessSelect },
                        },
                        _vm._l(_vm.assessSelectList, function (item) {
                          return _c(
                            "a-select-option",
                            {
                              key: item.label,
                              attrs: {
                                value: item.value,
                                disabled: item.disabled,
                              },
                            },
                            [_vm._v(_vm._s(item.label))]
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }