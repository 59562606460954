var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("iframe", {
    attrs: {
      id: _vm.id,
      frameborder: "0",
      width: "100%",
      height: "800px",
      scrolling: "auto",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }