<template>
  <!-- 考核规则 -->
  <div :class="['edit-assess-form']">
    <!-- 公式 -->
    <div class="assess-form-item">
      <a-row
        class="title-row"
        type="flex"
        align="middle"
        :gutter="complete.gutter"
      >
        <a-col v-bind="complete.col[0]">公式</a-col>
        <a-col
          class="gutter-row"
          v-bind="complete.col[1]"
          flex="1"
        >
          <template v-if="isEdit">
            <a-textarea
              auto-size
              v-model="formData.formula"
            />
          </template>
          <span
            class="formula"
            v-else
          >{{ formData.formula }}</span>
        </a-col>
        <a-col v-bind="modify.col[3]"></a-col>
      </a-row>
    </div>

    <!-- 选择后的页面结构 -->
    <template v-if="haveSelect(formData)">
      <!-- 完成情况 -->
      <div
        class="assess-form-item"
        v-if="formData.kpiDeliveries"
      >
        <a-row
          class="title-row"
          type="flex"
          align="middle"
          :gutter="complete.gutter"
        >
          <a-col v-bind="complete.col[0]">完成情况</a-col>
          <a-col
            class="gutter-row"
            v-bind="complete.col[1]"
          >
            <div class="gutter-box">分数</div>
          </a-col>
          <a-col
            class="gutter-row"
            v-bind="complete.col[2]"
            v-if="isEdit"
          >
            <div class="row-option">
              <a-button
                shape="circle"
                icon="close"
                :size="'small'"
                @click="delAssess('kpiDeliveries', true)"
              />
            </div>
          </a-col>
        </a-row>
        <a-row
          type="flex"
          align="middle"
          :gutter="complete.gutter"
          v-for="(row, r_idx) in formData.kpiDeliveries"
          :key="r_idx"
        >
          <a-col
            class="gutter-row"
            v-bind="complete.col[0]"
          >{{ getLabel(deliveriesTypeList, row.status) }}
          </a-col>
          <a-col
            class="gutter-row"
            v-bind="complete.col[1]"
          >
            <template v-if="isEdit">
              <input-number
                :formData="row"
                :valueKey="'score'"
              />
            </template>
            <template v-else>{{ row.score }}</template>
          </a-col>
        </a-row>
      </div>

      <!-- 错翻 -->
      <div
        class="assess-form-item"
        v-if="errorList.length"
      >
        <a-row
          class="title-row"
          type="flex"
          align="middle"
          :gutter="error.gutter"
        >
          <a-col v-bind="error.col[0]">错翻</a-col>
          <a-col
            class="gutter-row"
            v-bind="error.col[1]"
          >
            <div class="gutter-box">单个扣分分值</div>
          </a-col>
          <a-col
            class="gutter-row"
            v-bind="error.col[2]"
          >
            <div class="gutter-box">满分</div>
          </a-col>
          <a-col
            class="gutter-row"
            v-bind="error.col[3]"
            v-if="isEdit"
          >
            <div class="row-option">
              <a-button
                shape="circle"
                :size="'small'"
                icon="close"
                @click="delAssess('errorList')"
              />
            </div>
          </a-col>
        </a-row>

        <edit-error-form
          :isEdit="isEdit"
          :col="error.col"
          :gutter="error.gutter"
          :formData="formData"
          :needOption="false"
          :formList="errorList"
          :maxScoreKey="'errorMaxScore'"
          :errorTypeList="errorTypeTree"
        />
      </div>
      <!-- 硬伤 -->
      <div
        class="assess-form-item"
        v-if="hardInjuryList.length"
      >
        <a-row
          class="title-row"
          type="flex"
          align="middle"
          :gutter="error.gutter"
        >
          <a-col v-bind="error.col[0]">硬伤</a-col>
          <a-col
            class="gutter-row"
            v-bind="error.col[1]"
          >
            <div class="gutter-box">单个扣分分值</div>
          </a-col>
          <a-col
            class="gutter-row"
            v-bind="error.col[2]"
          >
            <div class="gutter-box">满分</div>
          </a-col>
          <a-col
            class="gutter-row"
            v-bind="error.col[3]"
            v-if="isEdit"
          >
            <div class="row-option">
              <a-button
                shape="circle"
                :size="'small'"
                icon="close"
                @click="delAssess('hardInjuryList')"
              />
            </div>
          </a-col>
        </a-row>

        <edit-error-form
          :isEdit="isEdit"
          :col="error.col"
          :gutter="error.gutter"
          :formData="formData"
          :formList="hardInjuryList"
          :maxScoreKey="'hardMaxScore'"
          :errorTypeList="hardInjuryTypeTree"
        />
      </div>
      <!-- 软伤 -->
      <div
        class="assess-form-item"
        v-if="softInjuryList.length"
      >
        <a-row
          class="title-row"
          type="flex"
          align="middle"
          :gutter="error.gutter"
        >
          <a-col v-bind="error.col[0]">软伤</a-col>
          <a-col
            class="gutter-row"
            v-bind="error.col[1]"
          >
            <div class="gutter-box">单个扣分分值</div>
          </a-col>
          <a-col
            class="gutter-row"
            v-bind="error.col[2]"
          >
            <div class="gutter-box">满分</div>
          </a-col>
          <a-col
            class="gutter-row"
            v-bind="error.col[3]"
            v-if="isEdit"
          >
            <div class="row-option">
              <a-button
                shape="circle"
                :size="'small'"
                icon="close"
                @click="delAssess('softInjuryList')"
              />
            </div>
          </a-col>
        </a-row>

        <edit-error-form
          :isEdit="isEdit"
          :col="error.col"
          :gutter="error.gutter"
          :formData="formData"
          :formList="softInjuryList"
          :maxScoreKey="'softMaxScore'"
          :errorTypeList="softInjuryTypeTree"
        />
      </div>

      <!-- 修改率 -->
      <div
        class="assess-form-item"
        v-if="formData.kpiModifies"
      >
        <a-row
          class="title-row"
          type="flex"
          align="middle"
          :gutter="modify.gutter"
        >
          <a-col v-bind="modify.col[0]">修改率</a-col>
          <a-col
            class="gutter-row"
            v-bind="modify.col[2]"
          >
            <div class="gutter-box">范围(%)</div>
          </a-col>
          <a-col
            class="gutter-row"
            v-bind="modify.col[1]"
          >
            <div class="gutter-box">分数</div>
          </a-col>
          <a-col
            class="gutter-row"
            v-bind="modify.col[3]"
            v-if="isEdit"
          >
            <div class="row-option">
              <a-button
                shape="circle"
                icon="close"
                :size="'small'"
                @click="delAssess('kpiModifies', true)"
              />
            </div>
          </a-col>
        </a-row>
        <a-row
          type="flex"
          align="middle"
          :gutter="modify.gutter"
          v-for="(row, r_idx) in formData.kpiModifies"
          :key="r_idx"
        >
          <!-- 修改率列 -->
          <a-col
            class="gutter-row"
            v-bind="modify.col[0]"
          >{{ getLabel(modifiesTypeList, row.type) }}</a-col>

          <!-- 范围列 -->
          <a-col
            class="gutter-row"
            v-bind="modify.col[2]"
          >
            <template v-if="isEdit">
              <edit-range-select
                :formData="row.range"
                :rangeKey="'type'"
                :valueKey="'value'"
                :needUnit="false"
                suffix="%"
              />
            </template>
            <template v-else>
              <edit-range-select
                suffix="%"
                :rangeObj="row.range"
              />
            </template>
          </a-col>
          <!-- 分数列 -->
          <a-col
            class="gutter-row"
            v-bind="modify.col[1]"
          >
            <template v-if="isEdit">
              <input-number
                :formData="row"
                :valueKey="'score'"
              />
            </template>
            <template v-else>{{ row.score }}</template>
          </a-col>
        </a-row>
      </div>
    </template>
    <div
      class="assess-form-item"
      v-if="isEdit"
    >
      <a-row
        class="title-row"
        type="flex"
        align="middle"
        :gutter="complete.gutter"
      >
        <a-col>
          <a-select
            class="only-select"
            placeholder="请选择新增项"
            :value="'请选择新增项'"
            @change="changeAssessSelect"
          >
            <a-select-option
              v-for="item in assessSelectList"
              :key="item.label"
              :value="item.value"
              :disabled="item.disabled"
            >{{ item.label }}</a-select-option>
          </a-select>
        </a-col>
      </a-row>
    </div>
  </div>
</template>

<script>
import InputNumber from '@/components/edit/input-number.vue'
import EditRangeSelect from '@/components/edit/edit-range-select.vue'
import EditLevelForm from '@/components/edit/form-list/edit-level-form.vue'
import EditErrorForm from '@/components/edit/form-list/edit-error-form.vue'
import { createDeliveriesTypeList } from '@/enums/deliveriesTypeList'
import { createModifiesTypeList } from '@/enums/modifiesTypeList'
import { createErrorTypeTree, createHardInjuryTypeTree, createSoftInjuryTypeTree } from '@/enums/errorTypeList'

export default {
  components: {
    InputNumber,
    EditLevelForm,
    EditErrorForm,
    EditRangeSelect,
  },
  props: {
    isEdit: {
      type: Boolean,
      default: false,
    },
    // 整个表单对象
    formData: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      assessSelectList: {
        kpiDeliveries: {
          label: '完成情况',
          value: 'kpiDeliveries',
          describe: '完成情况',
          disabled: false,
        },
        errorList: {
          label: '错翻',
          value: 'errorList',
          describe: '错翻',
          disabled: false,
        },
        hardInjuryList: {
          label: '硬伤',
          value: 'hardInjuryList',
          describe: '硬伤',
          disabled: false,
        },
        softInjuryList: {
          label: '软伤',
          value: 'softInjuryList',
          describe: '软伤',
          disabled: false,
        },
        kpiModifies: {
          label: '修改率',
          value: 'kpiModifies',
          describe: '修改率',
          disabled: false,
        },
      },
      assessSelect: null,
      // 完成情况
      complete: {
        col: [{ span: 5 }, { span: 8 }, { span: 11 }],
        gutter: 16,
      },
      // 软硬伤
      error: {
        col: [{ span: 5 }, { span: 8 }, { span: 8 }, { span: 3 }],
        gutter: 16,
      },
      // 修改率
      modify: {
        col: [{ span: 5 }, { span: 8 }, { span: 8 }, { span: 3 }],
        gutter: 16,
      },
      // 完成情况类型列表
      deliveriesTypeList: {},
      // 修改率类型列表
      modifiesTypeList: {},
      // 错翻选项
      errorTypeTree: {},
      // 错翻列表
      errorList: [],
      // 硬伤选项
      hardInjuryTypeTree: {},
      // 硬伤列表（用于渲染
      hardInjuryList: [],
      // 软伤选项
      softInjuryTypeTree: {},
      // 软伤列表（用于渲染
      softInjuryList: [],
    }
  },
  watch: {
    errorList: {
      handler() {
        this.setKpiErrors()
      },
      deep: true,
    },
    hardInjuryList: {
      handler() {
        this.setKpiErrors()
      },
      deep: true,
    },
    softInjuryList: {
      handler() {
        this.setKpiErrors()
      },
      deep: true,
    },
  },
  created() {
    this.deliveriesTypeList = createDeliveriesTypeList()
    this.modifiesTypeList = createModifiesTypeList()
    this.hardInjuryTypeTree = createHardInjuryTypeTree()
    this.softInjuryTypeTree = createSoftInjuryTypeTree()
    this.errorTypeTree = createErrorTypeTree()

    this.setShowList()
  },
  methods: {
    haveSelect(formData) {
      if (
        formData.kpiDeliveries ||
        this.errorList.length ||
        this.hardInjuryList.length ||
        this.softInjuryList.length ||
        formData.kpiModifies
      ) {
        return true
      } else {
        return false
      }
    },
    changeAssessSelect(e) {
      if (this.assessSelectList[e]) {
        this.assessSelectList[e].disabled = true
      }

      // 根据选择的类型，设置对应的表单结构
      switch (e) {
        case 'kpiDeliveries':
          let d_list = []
          let deliveries = this.deliveriesTypeList
          Object.keys(deliveries).forEach((key) => {
            d_list.push({
              status: key,
              score: '',
            })
          })
          this.formData.kpiDeliveries = d_list
          return
        case 'errorList':
          this.errorList = [
            {
              type: 'ERROR',
              score: null,
            },
          ]
          return
        case 'hardInjuryList':
          this.hardInjuryList = [
            {
              type: null,
              score: null,
            },
          ]
          return
        case 'softInjuryList':
          this.softInjuryList = [
            {
              type: null,
              score: null,
            },
          ]
          return
        case 'kpiModifies':
          let m_list = []
          let modifies = this.modifiesTypeList
          Object.keys(modifies).forEach((key) => {
            m_list.push({
              type: key,
              score: '',
              range: {
                type: 'GT',
                unit: 'CHAPTER',
                value: null,
                start: null,
                end: null,
                id: null,
              },
            })
          })
          this.formData.kpiModifies = m_list
          return
        default:
          console.warn('未知类型：', e)
      }
    },
    delAssess(type, isFormData) {
      if (isFormData) {
        this.formData[type] = null
      } else {
        this[type] = []
      }
      this.assessSelectList[type].disabled = false
    },
    getLabel(list, type) {
      let item = list[type]
      if (!item) return ''

      return item.label
    },
    setKpiErrors() {
      let list = [...this.hardInjuryList, ...this.softInjuryList, ...this.errorList]
      this.formData.kpiErrors = list
    },
    setShowList() {
      let errorList = []
      let hardInjuryList = []
      let softInjuryList = []
      this.formData.kpiErrors?.forEach((item) => {
        if (this.errorTypeTree[item.type]) {
          errorList.push(item)
        }
        if (this.hardInjuryTypeTree[item.type]) {
          hardInjuryList.push(item)
        }
        if (this.softInjuryTypeTree[item.type]) {
          softInjuryList.push(item)
        }
      })

      this.errorList = errorList
      this.hardInjuryList = hardInjuryList
      this.softInjuryList = softInjuryList

      // 回写新增下拉框的禁用状态
      if (this.errorList.length) {
        this.assessSelectList.errorList.disabled = true
      }
      if (this.hardInjuryList.length) {
        this.assessSelectList.hardInjuryList.disabled = true
      }
      if (this.softInjuryList.length) {
        this.assessSelectList.softInjuryList.disabled = true
      }
      if (this.formData.kpiModifies) {
        this.assessSelectList.kpiModifies.disabled = true
      }
      if (this.formData.kpiDeliveries) {
        this.assessSelectList.kpiDeliveries.disabled = true
      }
    },
  },
}
</script>

<style lang="less" scoped>
.edit-assess-form {
  .assess-form-item {
    margin-bottom: 30px;

    & > .ant-row,
    & > .ant-row-flex {
      padding: 15px 0 0 0;
    }

    .title-row {
      font-weight: bold;

      .formula {
        font-weight: normal;
        white-space: pre-wrap;
      }
    }

    .only-select {
      color: #bfbfbf;
    }

    .left-option {
      width: 100%;
      position: absolute;
      left: 0;
      top: 0;
    }

    .row-option {
      display: flex;
      justify-content: flex-end;

      button {
        &:last-child {
          margin-left: 6px;
        }
      }
    }
  }
}
</style>