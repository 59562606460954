var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("a-modal", {
    attrs: {
      keyboard: false,
      "mask-closable": false,
      closable: false,
      title: "填写人事档案",
      "body-style": { height: "70vh" },
      width: "80%",
    },
    on: { ok: _vm.hideModal },
    scopedSlots: _vm._u([
      {
        key: "footer",
        fn: function () {
          return [
            _c(
              "a-button",
              {
                key: "submit",
                attrs: { type: "primary", loading: _vm.loading },
                on: { click: _vm.handleOk },
              },
              [_vm._v(" 下一步 ")]
            ),
          ]
        },
        proxy: true,
      },
    ]),
    model: {
      value: _vm.guideVisible,
      callback: function ($$v) {
        _vm.guideVisible = $$v
      },
      expression: "guideVisible",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }