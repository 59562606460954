<template>
  <div :style="backCss">
    <div class="statistics-menu-list">
      <a-dropdown
        v-for="(item, index) in columns"
        :trigger="['click']"
        :ref="'statistics-' + index"
        :key="index"
        :style="item.isNum ? '' : 'display: none;'"
        :placement="'topCenter'"
      >
        <div class="statistics">统计<a-icon type="caret-up" /></div>
        <a-menu slot="overlay">
          <a-menu-item v-if="item.isNum" @click="compute('meanNum', item.dataIndex, index, '求和')">
            求和
          </a-menu-item>
          <a-menu-item v-if="item.isNum" @click="compute('sumNum', item.dataIndex, index, '平均')">
            平均
          </a-menu-item>
        </a-menu>
      </a-dropdown>
    </div>
    <a-spin :spinning="tableLoading">
      <a-dropdown v-model="visible" :placement="'bottomRight'" :trigger="['contextmenu']">
        <a-menu slot="overlay">
          <a-menu-item v-for="(item, index) in columns" :key="item.dataIndex">
            <a-checkbox v-model="item.checked" @change="checkChange(index)">
              {{ item.title }}
            </a-checkbox>
          </a-menu-item>
        </a-menu>
        <!-- @filter-change="filterChangeEvent" -->
        <vxe-table
          border="none"
          ref="xTree"
          :row-config="{ isCurrent: true, isHover: true }"
          :column-config="{ resizable: true }"
          @checkbox-change="selectChangeEvent"
          @checkbox-all="selectChangeEvent"
          @cell-click="cellClickEvent"
          @toggle-tree-expand="toggleExpandChangeEvent"
          :footer-method="footerMethod"
          :tree-config="{
            hasChild: 'hasChild',
            parentField: 'parentId',
            rowField: 'teamId',
            lazy: true,
            loadMethod: loadChildrenMethod
          }"
          :filter-config="{
            remote: true,
            iconNone: 'iconfont icon-shaixuan icon',
            iconMatch: 'iconfont icon-shaixuan icon filter-product-btn'
          }"
          :data="tableData"
          show-footer
        >
          <vxe-column align="center" fixed="left" type="checkbox" minWidth="80" show-overflow> </vxe-column>

          <vxe-column
            v-for="record in copyColumns"
            :key="record.dataIndex"
            :fixed="record.dataIndex == 'team' ? 'left' : ''"
            :field="record.dataIndex"
            :title="record.title"
            :minWidth="record.width"
            :tree-node="record.dataIndex == 'team'"
            show-overflow
          >
            <template v-if="record.dataIndex == 'team'" v-slot="{ row }">
              <div
                v-if="!row.hasChild"
                class="nick-name-div"
                @click.stop="toOtherCalender(row)"
                style="display:flex;align-items:center"
              >
                <div class="status-img">
                  <img
                    v-if="row.userFile.avatar"
                    :src="row.userFile.avatar"
                    style="width:35px;height:35px;border-radius: 50%;"
                    alt=""
                  />
                  <img v-else src="./../../assets/tx.png" style="width:35px;height:35px;border-radius: 50%;" alt="" />
                  <div
                    class="status"
                    :style="
                      row.onlineType == 1
                        ? 'background-color:#24B300'
                        : row.onlineType == 2
                        ? 'background-color:gray'
                        : 'background-color:#ADADAD'
                    "
                  ></div>
                </div>
                <span>{{ row.nickName }}</span>
              </div>
              <div v-else>
                {{ row.team || row.standbyTeam }}
                <span>{{ row.childs && row.childs.length ? '（' + row.childs.length + '）' : '' }}</span>
              </div>
            </template>
            <template v-else-if="record.dataIndex == 'userFile.workDays'" v-slot="{ row }">
              <template v-if="!row.hasChild">
                <a-tag
                  v-for="one in Array.from(row.userFile.workDays || []).sort((a, b) => {
                    return a - b
                  })"
                  :key="one"
                >
                  {{
                    one == '1'
                      ? '周一'
                      : one == '2'
                      ? '周二'
                      : one == '3'
                      ? '周三'
                      : one == '4'
                      ? '周四'
                      : one == '5'
                      ? '周五'
                      : one == '6'
                      ? '周六'
                      : one == '7'
                      ? '周日'
                      : one == '-1'
                      ? '跳过中国法定节假日'
                      : ''
                  }}
                </a-tag>
              </template>
            </template>
            <template v-else-if="record.dataIndex == 'userFile.qq'" v-slot="{ row }">
              <span v-if="!row.hasChild">
                <div v-if="row.userFile.qq">
                  <a-icon type="qq" style="width:1vw;color:hsl(0deg 0% 75%);" />
                  <span style="margin-left:3px">{{ row.userFile.qq }}</span>
                </div>
                <div v-if="row.userFile.qqType">
                  <a-icon type="wechat" style="width:1vw;color:hsl(0deg 0% 75%);" />
                  <span style="margin-left:3px">{{ row.userFile.qqType }}</span>
                </div>
              </span>
            </template>
            <template v-else-if="record.dataIndex == 'userFile.goodAtProductionType'" v-slot="{ row }">
              <span>
                <template v-if="!row.hasChild && row.userFile.goodAtProductionType">
                  <a-tag v-for="one in row.userFile.goodAtProductionType.split(',')" :key="one">
                    {{
                      one == '其他' ? (row.userFile.skilledProductOther ? row.userFile.skilledProductOther : one) : one
                    }}
                  </a-tag>
                </template>
              </span>
            </template>
            <template v-else-if="record.dataIndex == 'userFile.badAtProductionType'" v-slot="{ row }">
              <span>
                <template v-if="!row.hasChild && row.userFile.badAtProductionType">
                  <a-tag v-for="one in row.userFile.badAtProductionType.split(',')" :key="one">
                    {{
                      one == '其他'
                        ? row.userFile.unskilledProductOther
                          ? row.userFile.unskilledProductOther
                          : one
                        : one
                    }}
                  </a-tag>
                </template>
              </span>
            </template>
            <template v-else-if="record.dataIndex == 'weekAvailableCount'" v-slot="{ row }">{{
              !row.hasChild ? row.weekTotal : ''
            }}</template>
            <template v-else-if="record.dataIndex == 'userFile.workCity'" v-slot="{ row }">
              <span>
                <div>{{ !row.hasChild ? getWorkCity(row.userFile.workCity) : '' }}</div>
              </span>
            </template>
            <template v-else-if="record.dataIndex == 'userFile.weekFreeCount'" v-slot="{ row }">{{
              row.weekCanScheduleCount
            }}</template>
            <template v-else-if="record.dataIndex == 'weekTaskCount'" v-slot="{ row }">{{
              row.weekTaskCount
            }}</template>
            <template v-else-if="record.dataIndex == 'enabled'" v-slot="{ row }">
              <div v-if="!row.hasChild">
                <a-icon v-if="row.enabled" type="close-circle" />
                <a-icon v-else type="check-circle" />
                <span style="margin-left:4px;">{{ row.enabled ? '停用' : '启用' }}</span>
              </div>
            </template>
          </vxe-column>
        </vxe-table>
      </a-dropdown>
    </a-spin>
  </div>
</template>

<script>
import { getAction, postAction } from '@/api/manage'
import { citys } from '../v-table/modules/city.js'
import { simpleDebounce } from '@/utils/util'
import moment from 'moment'
export default {
  props: {
    queryParamProp: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      propertys: [],
      visible: false,
      columns: [
        {
          title: '部门-昵称',
          width: 200,
          scopedSlots: { customRender: 'team' },
          dataIndex: 'team',
          checked: true,
          isNum: false
        },
        /*         {
          title: '昵称',
          dataIndex: 'nickName',
          scopedSlots: { customRender: 'nickName' },
          width: 160,
          sorter: true,
          checked: true,
          isNum: false
        }, */
        /*         {
          title: '所属部门',
          width: 200,
          scopedSlots: { customRender: 'departs' },
          dataIndex: 'departs',
          checked: true,
          isNum: false
        },
        {
          title: '职位',
          width: 160,
          scopedSlots: { customRender: 'positions' },
          dataIndex: 'positions',
          checked: true,
          isNum: false
        }, */
        /* {
          title: '在线状态',
          width: 80,
          scopedSlots: { customRender: 'onlineType' },
          dataIndex: 'onlineType',
        }, */
        {
          title: '签约类型',
          width: 120,
          dataIndex: 'userFile.contractType',
          scopedSlots: { customRender: 'contractType' },
          checked: true,
          isNum: false
        },
        {
          title: '聊天工具',
          width: 160,
          dataIndex: 'userFile.qq',
          scopedSlots: { customRender: 'qq' },
          checked: true,
          isNum: false
        },
        {
          title: '工作地',
          width: 160,
          scopedSlots: { customRender: 'workCity' },
          dataIndex: 'userFile.workCity',
          checked: true,
          isNum: false
        },
        /*         {
          title: '签约类型',
          width: 120,
          dataIndex: 'userFile.contractType',
          checked: true,
          isNum: false
        }, */
        {
          title: '可工作日',
          width: 180,
          dataIndex: 'userFile.workDays',
          scopedSlots: { customRender: 'workDays' },
          checked: true,
          isNum: false
        },
        {
          title: '周可安排数量',
          width: 120,
          dataIndex: 'weekAvailableCount',
          scopedSlots: { customRender: 'weekAvailableCount' },
          checked: true,
          isNum: true
        },
/*         {
          title: '周已安排数量',
          width: 120,
          dataIndex: 'weekTaskCount',
          scopedSlots: { customRender: 'weekTaskCount' },
          checked: true,
          isNum: true
        },
        {
          title: '周空余数量',
          width: 110,
          dataIndex: 'userFile.weekFreeCount',
          scopedSlots: { customRender: 'weekFreeCount' },
          checked: true,
          isNum: true
        }, */
        {
          title: '擅长作品类型',
          width: 180,
          scopedSlots: { customRender: 'goodAtProductionType' },
          dataIndex: 'userFile.goodAtProductionType',
          checked: true,
          isNum: false
        },
        {
          title: '抵触作品类型',
          width: 180,
          scopedSlots: { customRender: 'badAtProductionType' },
          dataIndex: 'userFile.badAtProductionType',
          checked: true,
          isNum: false
        },
        {
          title: '账号状态',
          width: 100,
          scopedSlots: { customRender: 'enabled' },
          dataIndex: 'enabled',
          checked: true,
          isNum: false
        }
        /*         {
          title: '工作台账号',
          width: 100,
          dataIndex: 'onlyTool',
          scopedSlots: { customRender: 'onlyTool' },
        }, */
      ],
      copyColumns: [
        {
          title: '部门-昵称',
          width: 200,
          scopedSlots: { customRender: 'team' },
          dataIndex: 'team',
          checked: true,
          isNum: false
        },
        /*         {
          title: '昵称',
          dataIndex: 'nickName',
          scopedSlots: { customRender: 'nickName' },
          width: 160,
          sorter: true,
          checked: true,
          isNum: false
        }, */
        /*         {
          title: '所属部门',
          width: 200,
          scopedSlots: { customRender: 'departs' },
          dataIndex: 'departs',
          checked: true,
          isNum: false
        },
        {
          title: '职位',
          width: 160,
          scopedSlots: { customRender: 'positions' },
          dataIndex: 'positions',
          checked: true,
          isNum: false
        }, */
        /* {
          title: '在线状态',
          width: 80,
          scopedSlots: { customRender: 'onlineType' },
          dataIndex: 'onlineType',
        }, */
        {
          title: '签约类型',
          width: 120,
          dataIndex: 'userFile.contractType',
          scopedSlots: { customRender: 'contractType' },
          checked: true,
          isNum: false
        },
        {
          title: '聊天工具',
          width: 160,
          dataIndex: 'userFile.qq',
          scopedSlots: { customRender: 'qq' },
          checked: true,
          isNum: false
        },
        {
          title: '工作地',
          width: 160,
          scopedSlots: { customRender: 'workCity' },
          dataIndex: 'userFile.workCity',
          checked: true,
          isNum: false
        },
        /*         {
          title: '签约类型',
          width: 120,
          dataIndex: 'userFile.contractType',
          checked: true,
          isNum: false
        }, */
        {
          title: '可工作日',
          width: 180,
          dataIndex: 'userFile.workDays',
          scopedSlots: { customRender: 'workDays' },
          checked: true,
          isNum: false
        },
        {
          title: '周可安排数量',
          width: 120,
          dataIndex: 'weekAvailableCount',
          scopedSlots: { customRender: 'weekAvailableCount' },
          checked: true,
          isNum: true
        },
/*         {
          title: '周已安排数量',
          width: 120,
          dataIndex: 'weekTaskCount',
          scopedSlots: { customRender: 'weekTaskCount' },
          checked: true,
          isNum: true
        },
        {
          title: '周空余数量',
          width: 110,
          dataIndex: 'userFile.weekFreeCount',
          scopedSlots: { customRender: 'weekFreeCount' },
          checked: true,
          isNum: true
        }, */
        {
          title: '擅长作品类型',
          width: 180,
          scopedSlots: { customRender: 'goodAtProductionType' },
          dataIndex: 'userFile.goodAtProductionType',
          checked: true,
          isNum: false
        },
        {
          title: '抵触作品类型',
          width: 180,
          scopedSlots: { customRender: 'badAtProductionType' },
          dataIndex: 'userFile.badAtProductionType',
          checked: true,
          isNum: false
        },
        {
          title: '账号状态',
          width: 100,
          scopedSlots: { customRender: 'enabled' },
          dataIndex: 'enabled',
          checked: true,
          isNum: false
        }
        /*         {
          title: '工作台账号',
          width: 100,
          dataIndex: 'onlyTool',
          scopedSlots: { customRender: 'onlyTool' },
        }, */
      ],
      tableData: [],
      selectRows: [],
      queryParmas: {
        current: 1,
        size: 50
      },
      color: '',
      productName: '',
      tableLoading: false,
      selectedTreeKeys: [],
      url: {
        syncUser: '/act/process/extActProcess/doSyncUser',
        list: '/sys/user/list',
        delete: '/sys/user/delete',
        deleteBatch: '/sys/user/deleteBatch',
        exportXlsUrl: '/sys/user/exportXls',
        importExcelUrl: 'sys/user/importExcel'
      },
      treeData: [],
      contractTypeMap: {
        全职: '0',
        兼职: '1',
        意向全职: '2',
        实习: '3',
        暂无签约类型: '4'
      }
    }
  },
  created() {
    this.loadData()
  },
  mounted() {
    setTimeout(() => {
      for (let css of document.styleSheets) {
        for (let rule of css.cssRules) {
          if (rule.cssText?.includes('.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected')) {
            this.color = rule.style.backgroundColor
            localStorage.setItem(
              'color',
              JSON.stringify({ hoverColor: this.color, color: this.$store.state.app.color })
            )
          }
        }
      }
    }, 50)
  },
  computed: {
    backCss() {
      let colorBack = this.color
      let color = this.$store.state.app.color
      return {
        '--back-color': colorBack,
        '--theme-color': color
      }
    }
  },
  watch: {},
  methods: {
    moment,
    toOtherCalender(row) {
      window.open(`/system/otherTasks/${row.id}?&name=他人日历`)
    },
    checkChange(menuIndex) {
      this.copyColumns = this.columns.filter(item => {
        return item.checked
      })
      Array.from(document.getElementsByClassName('vxe-footer--row')[0].childNodes)
        .filter((item, index) => {
          return index != 0
        })
        .forEach((item, i) => {
          try {
            if (i == menuIndex) {
              if (this.columns[i].checked) {
                item.style.display = 'table-cell'
              } else {
                item.style.display = 'none'
              }
            }
          } catch (err) {}
        })
    },
    async loadChildrenMethod({ row }) {
      // 异步加载子节点
      let arr = []
      if (row.isDepart && !row.isPostion) {
        this.treeData.forEach(item => {
          if (item.departs.some(i => i.id == row.teamId)) {
            if (item.positions.length) {
              item.positions.forEach(p => {
                if (!arr.length || arr?.every(a => a.team != p.name)) {
                  arr.push({
                    team: p.name,
                    isDepart: false,
                    isPostion: true,
                    hasChild: true,
                    teamId: row.teamId + '-' + p.id,
                    sequenceNo: p.sequenceNo,
                    childs: []
                  })
                }
              })
            } else {
              if (arr.every(item => item.team != '无职位')) {
                arr.push({
                  team: '无职位',
                  isDepart: false,
                  isPostion: true,
                  hasChild: true,
                  teamId: row.teamId + '-no',
                  childs: []
                })
              }
            }
          }
        })
        this.treeData.forEach(item => {
          if (!item.userFile.contractType) {
            item.userFile.contractType = '暂无签约类型'
          }
          arr.forEach(r => {
            if (
              item.departs.some(i => i.id == r.teamId.split('-')[0]) &&
              item.positions.some(p => p.id == r.teamId.split('-')[1])
            ) {
              r.childs.push({
                hasChild: false,
                teamId: row.teamId + item.id,
                ...item
              })
            } else if (
              r.teamId.indexOf('no') > -1 &&
              !item.positions.length &&
              item.departs.some(i => i.id == r.teamId.split('-')[0])
            ) {
              r.childs.push({
                hasChild: false,
                teamId: row.teamId + item.id,
                ...item
              })
            }
          })
        })
        arr.forEach(r => {
          r.childs = r.childs.sort((a, b) => {
            return this.contractTypeMap[a.userFile.contractType] - this.contractTypeMap[b.userFile.contractType]
          })
        })
        arr = arr.sort((a, b) => {
          return a.sequenceNo - b.sequenceNo
        })
      } else if (!row.isDepart && row.isPostion) {
        /* this.treeData.forEach(item => {
          if (
            item.departs.some(i => i.id == row.teamId.split('-')[0]) &&
            item.positions.some(p => p.id == row.teamId.split('-')[1])
          ) {
            if (arr.every(a => a.team != item.userFile.contractType)) {
              arr.push({
                team: item.userFile.contractType,
                isDepart: false,
                isPostion: false,
                hasChild: true,
                teamId: row.teamId + '-' + item.id + '-' + item.userFile.contractType,
                standbyTeam:item.userFile.contractType||'暂无签约类型'
              })
            }
          }
        })
        arr = arr.sort((a, b) => {
          return this.contractTypeMap[a.team] - this.contractTypeMap[b.team]
        }) */
        arr = row.childs
      }
      return arr
    },
    treeExpand(keys) {
      this.defaultOpenKeys = keys
    },
    treeSelect(type, selectedKeys, info) {
      console.log(selectedKeys, info)
      this.loadData()
    },
    toggleExpandChangeEvent({ row, expanded }) {
      this.$refs.xTree.toggleTreeExpand(row)
      this.$refs.xTree.updateData()
      return
    },
    compute(methodName, index, menuIndex, menuText) {
      /* this.property='weekTaskCount' */
      this.methodName = methodName
      if (
        !this.propertys.some(item => {
          return item == index
        })
      ) {
        this.propertys.push(index)
      }
      this.$nextTick(() => {
        this.$refs.xTree.updateFooter() //手动更新表尾
        setTimeout(() => {
          this.$refs.xTree.setCheckboxRow(this.selectRows, true)
          Array.from(document.getElementsByClassName('vxe-footer--row')[0].childNodes)
            .filter((item, index) => {
              return index != 0
            })
            .forEach((item, i) => {
              try {
                if (i == menuIndex) {
                  this.$refs['statistics-' + i][0].$el.innerText = menuText + '：' + this[methodName]([], index)
                }
                if (this.$refs['statistics-' + i][0].$el.innerText != '统计' && i == menuIndex) {
                  item.childNodes[0].childNodes[0].innerText = ''
                  item.childNodes[0].childNodes[0].style.opacity = '1'
                }
                item.childNodes[0].childNodes[0].appendChild(this.$refs['statistics-' + i][0].$el)
              } catch (err) {}
            })
        }, 0)
      })
    },
    computeEval(methodName, index, menuIndex, menuText) {
      /* this.property='weekTaskCount' */
      this.methodName = methodName
      if (
        !this.propertys.some(item => {
          return item == index
        })
      ) {
        this.propertys.push(index)
      }
      this.$nextTick(() => {
        this.$refs.xTree.updateFooter() //手动更新表尾
        setTimeout(() => {
          this.$refs.xTree.setCheckboxRow(this.selectRows, true)
          Array.from(document.getElementsByClassName('vxe-footer--row')[0].childNodes)
            .filter((item, index) => {
              return index != 0
            })
            .forEach((item, i) => {
              try {
                if (i == menuIndex) {
                  this.$refs['statistics-' + i][0].$el.innerText =
                    menuText + '：' + this[methodName]([], index, menuText)
                }
                if (this.$refs['statistics-' + i][0].$el.innerText != '统计') {
                  item.childNodes[0].childNodes[0].innerText = ''
                  item.childNodes[0].childNodes[0].style.opacity = '1'
                }
                item.childNodes[0].childNodes[0].appendChild(this.$refs['statistics-' + i][0].$el)
              } catch (err) {}
            })
        }, 0)
      })
    },
    sumNumEval(list, field, menuText) {
      list = this.selectRows.filter(item => item.chapterSortAndStageName)
      let type = ''
      if (menuText == '软伤') {
        type = 'SOFT'
      } else if (menuText == '硬伤') {
        type = 'HARD'
      } else if (menuText == '修改率') {
        type = 'kpiModifyRatio'
      } else if (menuText == '错翻') {
        type = 'ERROR'
      }
      console.log(list)
      let count = 0
      if (type == 'SOFT') {
        list.forEach(item => {
          item.evalResult.kpiValueErrors?.forEach(i => {
            if (i.type == 'SOFT') {
              count += i.count - 0
            }
          })
        })
      } else if (type == 'HARD') {
        list.forEach(item => {
          item.evalResult.kpiValueErrors?.forEach(i => {
            if (i.type == 'HARD') {
              count += i.count - 0
            }
          })
        })
      } else if (type == 'ERROR') {
        list.forEach(item => {
          item.evalResult.kpiValueErrors?.forEach(i => {
            if (i.type == 'ERROR') {
              count += i.count - 0
            }
          })
        })
      } else if (type == 'kpiModifyRatio') {
        list.forEach(item => {
          if (item.evalResult.kpiModifyRatio) {
            count = count - 0 + (item.evalResult.kpiModifyRatio - 0)
          }
        })
      }
      let num = count / list.length
      if (type == 'kpiModifyRatio') {
        return num.toFixed(2) + '%'
      }
      return num.toFixed(2) || 0
    },
    meanNum(list, field) {
      list = this.selectRows
      if (field == 'weekAvailableCount') {
        let count = 0
        list.forEach(item => {
          count += Number(item.weekTotal)
        })
        return count || 0
      }
      if (field.split('.')[0] != 'userFile') {
        let count = 0
        list.forEach(item => {
          count += Number(item[field])
        })
        return count || 0
      } else {
        let count = 0
        list.forEach(item => {
          count += Number(item.userFile[field.split('.')[1]])
        })
        return count || 0
      }
    },
    allNum(list, field) {
      return list.length
    },
    sumNum(list, field) {
      list = this.selectRows
      if(!list.length) return 0
      if (field == 'weekAvailableCount') {
        let count = 0
        list.forEach(item => {
          count += Number(item.weekTotal)
        })
        let num = count / list.length
        return num.toFixed(2) || 0
      }
      if (field.split('.')[0] != 'userFile') {
        let count = 0
        list.forEach(item => {
          count += Number(item[field])
        })
        let num = count / list.length
        return num.toFixed(2) || 0
      } else {
        let count = 0
        list.forEach(item => {
          count += Number(item.userFile[field.split('.')[1]])
        })
        let num = count / list.length
        return num.toFixed(2) || 0
      }
    },
    cellClickEvent(e) {
      if (e.row.editable) return
      if (!e._columnIndex) return
      if (!e.row.hasChild && e._columnIndex != '1') {
        this.$emit('openModal', e.row)
        return
      }
      if (e.row.expend) {
        e.row.expend = false
        this.$refs.xTree.setTreeExpand(e.row, false)
      } else {
        e.row.expend = true
        this.$refs.xTree.setTreeExpand(e.row, true)
      }
      this.$refs.xTree.updateData()
      return
    },
    footerMethod({ columns, data }) {
      return [
        columns.map((column, columnIndex) => {
          if (this.propertys.includes(column.property)) {
            return parseInt(this[this.methodName](data, column.property))
          }
          if (columnIndex == 0) {
            return '已选择：' + this.selectRows.filter(item => !item.hasChild).length + '项'
          }
          return ''
        })
      ]
    },
    uniqueFunc(arr, uniId) {
      const res = new Map()
      return arr.filter(item => !res.has(item[uniId]) && res.set(item[uniId], 1))
    },
    loadData: simpleDebounce(async function() {
      this.getTableData()
    }, 200),
    async getTableData() {
      this.tableLoading = true
      this.queryParmas = {
        current: 1,
        size: -1
      }
      if (this.queryParamProp.nickName) this.queryParmas.nickName = '*' + this.queryParamProp.nickName + '*'
      const res = await getAction(this.url.list, this.queryParmas)
      /* const res = await getAction('/sys/user/list/v2')
      console.log(res,123) */
      try {
        if (res.code == 200) {
          this.clearSelectKeys()
          this.tableData = []
          res.data.records = res.data.records.sort((a, b) => a.nickName.localeCompare(b.nickName))
          this.treeData = res.data.records
          res.data.records.forEach(item => {
            if (
              this.tableData.every(i => {
                return item.departs.every(d => d.departName != i.team)
              })
            ) {
              item.departs.forEach(d => {
                this.tableData.push({
                  team: d.departName,
                  isDepart: true,
                  hasChild: true,
                  isPostion: false,
                  teamId: d.id
                })
              })
            }
          }) 
          this.tableLoading = false
          this.$refs.xTree.updateData()
          Array.from(document.getElementsByClassName('vxe-footer--row')[0].childNodes)
            .filter((item, index) => {
              return index != 0
            })
            .forEach((item, index) => {
              if (item.childNodes[0]) {
                item.childNodes[0].childNodes[0].appendChild(this.$refs['statistics-' + index][0].$el)
              }
            })
          setTimeout(() => {
            if (this.queryParamProp.nickName) {
              this.$refs.xTree.setAllTreeExpand(true)
            }
          }, 0)
          setTimeout(() => {
            if (this.queryParamProp.nickName) {
              this.$refs.xTree.setAllTreeExpand(true)
            }
          }, 200)
          /*           setTimeout(() => {
            if (this.queryParamProp.nickName) {
              this.$refs.xTree.setAllTreeExpand(true)
            }
          }, 300) */
        }
      } catch (err) {}
    },
    clearSelectKeys() {
      this.$emit('setRowkeys', [])
      this.$refs.xTree.clearCheckboxRow()
      this.selectRows = []
      this.$refs.xTree.updateFooter() //手动更新表尾
    },
    selectChangeEvent() {
      const records = this.$refs.xTree.getCheckboxRecords()
      this.$emit(
        'setRowkeys',
        records.filter(item => !item.hasChild)
      )
      this.selectRows = records.filter(item => !item.hasChild)
      this.$refs.xTree.updateFooter() //手动更新表尾
    },
    getWorkCity(data) {
      let text = ''
      if (data) {
        data = data.split(',')
        citys.options.forEach(item => {
          if (item.value == data[data.length - 1]) {
            text = item.label
            return text
          }
          item.children?.forEach(i => {
            if (i.value == data[data.length - 1]) {
              text = i.label
              return text
            }
            i.children?.forEach(e => {
              if (e.value == data[data.length - 1]) {
                text = i.label + '/' + e.label
                return text
              }
            })
          })
        })
      }
      return text
    }
  }
}
</script>

<style lang="less" scoped>
/* /deep/ .vxe-table--fixed-wrapper .vxe-table--footer-wrapper{
    display: none !important;
  } */
.nick-name-div:hover {
  color: var(--theme-color);
  cursor: pointer;
  img {
    border: 1px solid var(--theme-color);
  }
}
/deep/ .vxe-table--render-default .vxe-table--fixed-right-wrapper {
  box-shadow: none !important;
}
/deep/ .vxe-filter--btn {
  color: rgba(0, 0, 0, 0.65);
  font-size: 16px;
  position: relative;
  top: -2px;
}
/deep/ .vxe-filter--btn:hover {
  color: var(--theme-color);
}
.tree-page {
  position: absolute;
  width: 190px;
  left: -202px;
  top: 12px;
}
/deep/ .vxe-table--body-wrapper {
  max-height: calc(100vh - 335px) !important;
}
/* /deep/ .vxe-table--fixed-left-wrapper {
    .vxe-table--body-wrapper{
      max-height: var(--table-height) !important;
    }
  } */
.review-product:hover {
  color: var(--theme-color);
  cursor: pointer;
}
.anticon-check-circle {
  color: var(--theme-color);
}
.statistics {
  cursor: pointer;
  width: 100%;
  margin-left: -12px;
  &:hover {
    background: var(--back-color);
  }
}
.icon-list {
  display: flex;
  justify-content: end;
  padding-bottom: 10px;
  position: absolute;
  right: 0;
  top: 12px;
  i {
    &:hover {
      cursor: pointer;
    }
  }
}
.statistics-show {
  cursor: pointer;
  width: 100%;
  margin-left: -12px;
}
.statistics-menu-list {
  opacity: 0;
  height: 0;
}
/deep/ .vxe-footer--row {
  .vxe-cell--item {
    width: 100%;
    display: flex !important;
    align-items: center !important;
    justify-content: start;
    opacity: 0;
    &:hover {
      opacity: 1;
    }
  }
  .vxe-footer--column {
    &:first-child {
      .vxe-cell--item {
        opacity: 1;
        width: 120px;
      }
    }
  }
}
/deep/ .vxe-cell--sort {
  font-size:12px !important;
  width: 1em !important;
  height: 1.35em !important;
  margin-right: 7px;
}
/deep/ .vxe-cell--filter {
  font-size: 12px !important;
  margin-top: 2px;
}
/deep/ .vxe-header--row {
  .vxe-cell {
    display: flex;
    align-items: center;
    .vxe-cell--title {
      flex: 1;
    }
  }
}
.status-img {
  display: inline-block;
  position: relative;
  margin-right: 8px;
  .status {
    width: 10px;
    height: 10px;
    position: absolute;
    right: 0;
    bottom: 0;
    border-radius: 50%;
  }
}
/deep/ .is--indeterminate .vxe-checkbox--icon::before {
  background-color: #fff !important;
}
/deep/ .vxe-checkbox--icon:hover {
  &::before {
    border-color: var(--theme-color) !important;
  }
}
/deep/ .is--checked .vxe-icon-checkbox-checked {
  color: var(--theme-color) !important;
}
/deep/ .vxe-table--render-default .vxe-cell--checkbox:not(.is--disabled):hover .vxe-checkbox--icon {
  color: var(--theme-color) !important;
}
/deep/ .vxe-table--render-default .vxe-cell--checkbox .vxe-checkbox--icon {
  font-weight: 400 !important;
}
/deep/ .vxe-table--render-default .vxe-body--row.row--current {
  background-color: var(--back-color) !important;
}
/deep/ .vxe-table--render-default .is--indeterminate.vxe-cell--checkbox .vxe-checkbox--icon {
  color: var(--theme-color) !important;
}
/deep/ .vxe-table--border-line {
  border-left-width: 0 !important;
  border-right-width: 0 !important;
  border-bottom-width: 0 !important;
  border-top-width: 0 !important;
}
/deep/ .is--filter-active .vxe-cell--filter .vxe-filter--btn {
  color: var(--theme-color) !important;
}
/deep/ .vxe-table .vxe-sort--asc-btn.sort--active {
  color: var(--theme-color) !important;
}
/deep/ .vxe-table .vxe-sort--asc-btn.sort--active {
  color: var(--theme-color) !important;
}
/deep/ .is--checked {
  color: var(--theme-color) !important;
}
/deep/ .is--indeterminate.vxe-table--filter-option .vxe-checkbox--icon {
  color: var(--theme-color) !important;
}
/deep/ .vxe-table--filter-option:not(.is--disabled):hover .vxe-checkbox--icon {
  color: var(--theme-color) !important;
}
/deep/ .vxe-table--filter-wrapper .vxe-table--filter-footer button:hover {
  color: var(--theme-color) !important;
}
/deep/ .vxe-table--filter-wrapper .vxe-table--filter-footer {
  display: flex;
  justify-content: space-evenly;
  button {
    &:hover {
      color: var(--theme-color);
    }
  }
}
/* /deep/ .fixed-right--wrapper .col_3{
    opacity: 0 !important;
  } */
/deep/ .vxe-table--filter-wrapper {
  z-index: 9999;
}
/deep/ .is--indeterminate.vxe-table--filter-option {
  color: var(--theme-color) !important;
}
/deep/ .vxe-table--body-wrapper {
  .vxe-body--x-space {
    display: none;
  }
}
/deep/ .vxe-table--render-default .fixed--hidden {
  visibility: unset !important;
}
/deep/ .vxe-table--fixed-wrapper .vxe-body--row:last-child .vxe-cell {
  height: auto !important;
}
.vxe-table--fixed-left-wrapper::-webkit-scrollbar {
  width: 0;
  height: 0;
}
/deep/ .vxe-body--column {
  border-top: 1px solid rgb(233, 232, 232) !important;
}
/deep/ .vxe-table--footer-wrapper {
  border-top: 1px solid rgb(233, 232, 232) !important;
}
/deep/ .vxe-header--row {
  background-color: #f8f8f9;
}
/deep/ .vxe-header--row .vxe-cell {
  font-weight: 400 !important;
}
</style>
