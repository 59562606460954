<template>
  <a-modal
    v-model="guideVisible"
    :keyboard="false"
    :mask-closable="false"
    :closable="false"
    title="填写人事档案"
    :body-style="{ height: '70vh' }"
    width="80%"
    @ok="hideModal"
  >
    <!-- <a-steps :current="0">
      <a-step title="填写人事档案" sub-title="" description="" />
      <a-step title="阅读人员须知" sub-title="" description="评级及结算规则" />
      <a-step title="完成" sub-title="" description="" />
    </a-steps> -->
    
    <template #footer>
      <!-- <a-button key="back" @click="handleCancel"> Return </a-button> -->
      <a-button key="submit" type="primary" :loading="loading" @click="handleOk"> 下一步 </a-button>
    </template>
  </a-modal>
</template>

<script>
export default {
  data() {
    return { guideVisible: true }
  },
}
</script>

<style>
</style>