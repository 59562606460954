var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "standard-table" },
    [
      _c(
        "div",
        { staticClass: "alert" },
        [
          _c("a-alert", { attrs: { type: "info", "show-icon": true } }, [
            _c(
              "div",
              { attrs: { slot: "message" }, slot: "message" },
              [
                _vm._v("\n        已选择 "),
                _c("a", { staticStyle: { "font-weight": "600" } }, [
                  _vm._v(_vm._s(_vm.selectedRows.length)),
                ]),
                _vm._v("  \n        "),
                _vm._l(_vm.needTotalList, function (item, index) {
                  return item.needTotal
                    ? [
                        _vm._v(
                          "\n          " +
                            _vm._s(item.title) +
                            " 总计 \n          "
                        ),
                        _c(
                          "a",
                          { key: index, staticStyle: { "font-weight": "600" } },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  item.customRender
                                    ? item.customRender(item.total)
                                    : item.total
                                ) +
                                "\n          "
                            ),
                          ]
                        ),
                        _vm._v("  \n        "),
                      ]
                    : _vm._e()
                }),
                _c(
                  "a",
                  {
                    staticStyle: { "margin-left": "24px" },
                    on: { click: _vm.onClearSelected },
                  },
                  [_vm._v("清空")]
                ),
              ],
              2
            ),
          ]),
        ],
        1
      ),
      _c("a-table", {
        attrs: {
          size: _vm.size,
          bordered: _vm.bordered,
          loading: _vm.loading,
          columns: _vm.columns,
          dataSource: _vm.current,
          rowKey: _vm.rowKey,
          pagination: _vm.pagination,
          rowSelection: {
            selectedRowKeys: _vm.selectedRowKeys,
            onChange: _vm.updateSelect,
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }