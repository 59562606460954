var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.avalid
    ? _c(
        "div",
        { staticClass: "components-input-demo-presuffix" },
        [
          _c(
            "a-input",
            {
              attrs: {
                placeholder: _vm.placeholder,
                readOnly: "",
                disabled: _vm.disabled,
              },
              on: { click: _vm.openModal },
              model: {
                value: _vm.showText,
                callback: function ($$v) {
                  _vm.showText = $$v
                },
                expression: "showText",
              },
            },
            [
              _c("a-icon", {
                attrs: { slot: "prefix", type: "cluster", title: _vm.title },
                slot: "prefix",
              }),
              _vm.showText
                ? _c("a-icon", {
                    attrs: {
                      slot: "suffix",
                      type: "close-circle",
                      title: "清空",
                    },
                    on: { click: _vm.handleEmpty },
                    slot: "suffix",
                  })
                : _vm._e(),
            ],
            1
          ),
          _c("j-popup-onl-report", {
            ref: "jPopupOnlReport",
            attrs: {
              code: _vm.code,
              multi: _vm.multi,
              groupId: _vm.uniqGroupId,
              param: _vm.param,
            },
            on: { ok: _vm.callBack },
          }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }