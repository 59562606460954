<template>
    <!-- 考核规则-软硬伤-可编辑表单列表 -->
    <div class="edit-form-list">
        <template v-if="isEdit">
            <a-row type="flex" align="middle" class="edit-form-row" :gutter="gutter" v-for="(item, idx) in formList"
                :key="idx">
                <a-col class="gutter-row" v-bind="col[0]">
                    <template v-if="needOption">
                        <cascader :defaultValue="[item.type]" :formData="item" :valueKey="'type'"
                            :options="Object.values(errorTypeList)" />
                    </template>
                    <template v-else>{{ getLabel(errorTypeList, item.type) }}</template>
                </a-col>
                <a-col class="gutter-row" v-bind="col[1]">
                    <input-number :formData="item" :valueKey="'score'" />
                </a-col>
                <a-col class="gutter-row big-input-col" v-bind="col[2]">
                    <div class="big-input-box"
                        :style="{ height: `${(formList.length * 47) - 15}px`, '--pb': `${(formList.length - 1) * 47}px` }"
                        v-if="idx == 0">
                        <input-number class="big-input" :formData="formData" :valueKey="maxScoreKey" />
                    </div>
                </a-col>
                <a-col class="gutter-row" v-bind="col[3]">
                    <div class="row-option" v-if="needOption">
                        <a-button shape="circle" :size="'small'" icon="minus" @click="delRow(idx)" />
                        <a-button shape="circle" :size="'small'" icon="plus" @click="addRow(idx)" />
                    </div>
                </a-col>
            </a-row>
        </template>
        <template v-else>
            <a-row :gutter="gutter" v-for="(item, idx) in formList" :key="idx">
                <a-col class="gutter-row" v-bind="col[0]">{{ getLabel(errorTypeList, item.type) }}</a-col>
                <a-col class="gutter-row" v-bind="col[1]">{{ item.score }}</a-col>
                <a-col class="gutter-row" v-bind="col[2]" v-if="idx == 0">{{ formData[maxScoreKey] }}</a-col>
            </a-row>
        </template>
    </div>
</template>

<script>
import EditRangeSelect from "@/components/edit/edit-range-select.vue";
import Cascader from "@/components/edit/cascader.vue";
import InputNumber from "@/components/edit/input-number.vue";

export default {
    name: "edit-error-form",
    props: {
        col: {
            type: Array,
            default: () => []
        },
        gutter: {
            type: Number,
            default: 16
        },
        isEdit: {
            type: Boolean,
            default: false
        },
        errorTypeList: {
            type: Object,
            default: () => ({})
        },
        needOption: {
            type: Boolean,
            default: true
        },
        maxScoreKey: {
            type: String,
            default: ""
        },
        formData: {
            type: Object,
            default: () => ({})
        },
        formList: {
            type: Array,
            default: () => []
        }
    },
    components: {
        Cascader,
        InputNumber,
        EditRangeSelect
    },
    mounted() {
        if (!this.formList.length) {
            this.formList.push(this.createRow());
        }
    },
    methods: {
        delRow(index) {
            // 删除需要加上二次确认框，并请求删除接口
            if (this.formList.length == 1) {
                this.formList.splice(index, 1, this.createRow());
            } else {
                this.formList.splice(index, 1);
            }
        },
        addRow(index) {
            this.formList.splice(index + 1, 0, this.createRow());
        },
        getLabel(list, type) {
            let item = list[type];
            if (!item) return "";

            return item.label;
        },
        createRow() {
            return {
                type: null,
                score: null
            };
        }
    }
};
</script>

<style scoped lang="less">
.edit-form-list {
    position: relative;

    .ant-row,
    .ant-row-flex {
        padding: 15px 0 0 0;

        /deep/ .ant-input-affix-wrapper {
            .ant-input-prefix {
                left: 8px;
            }

            .ant-input {
                padding-left: 24px;
            }
        }
    }

    .title-row {
        font-weight: bold;
    }

    .row-option {
        display: flex;
        justify-content: flex-end;

        button {
            &:last-child {
                margin-left: 6px;
            }
        }
    }

    .edit-form-row {
        .row-option {
            display: none;
        }

        &:hover .row-option {
            display: flex;
        }
    }

    .big-input-col {
        position: relative;

        .big-input-box {
            // width: 100%;
            width: calc(100% - 16px);
            position: absolute;
            top: -16px;

            .big-input {
                height: 100%;

                /deep/ .ant-input-number-input-wrap {
                    height: 100%;

                    input {
                        height: 100%;
                        transition: unset;
                        padding-bottom: var(--pb);
                    }
                }
            }
        }
    }
}
</style>