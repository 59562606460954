var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm._l([_vm.innerFile || {}], function (file, fileKey) {
        return _vm.hasFile
          ? [
              _c(
                "div",
                { key: fileKey, staticStyle: { position: "relative" } },
                [
                  !file || !(file["url"] || file["path"] || file["message"])
                    ? [
                        _c(
                          "a-tooltip",
                          {
                            attrs: {
                              title:
                                "请稍后: " +
                                JSON.stringify(file) +
                                (file["url"] ||
                                  file["path"] ||
                                  file["message"]),
                            },
                          },
                          [_c("a-icon", { attrs: { type: "loading" } })],
                          1
                        ),
                      ]
                    : file["path"]
                    ? [
                        _c("img", {
                          staticClass: "j-editable-image",
                          attrs: { src: _vm.imgSrc, alt: "无图片" },
                          on: { click: _vm.handleMoreOperation },
                        }),
                      ]
                    : [
                        _c("a-icon", {
                          staticStyle: { color: "red" },
                          attrs: { type: "exclamation-circle" },
                          on: { click: _vm.handleClickShowImageError },
                        }),
                      ],
                  _c(
                    "template",
                    { staticStyle: { width: "30px" }, slot: "addonBefore" },
                    [
                      file.status === "uploading"
                        ? _c(
                            "a-tooltip",
                            {
                              attrs: {
                                title: `上传中(${Math.floor(file.percent)}%)`,
                              },
                            },
                            [_c("a-icon", { attrs: { type: "loading" } })],
                            1
                          )
                        : file.status === "done"
                        ? _c(
                            "a-tooltip",
                            { attrs: { title: "上传完成" } },
                            [
                              _c("a-icon", {
                                staticStyle: { color: "#00DB00" },
                                attrs: { type: "check-circle" },
                              }),
                            ],
                            1
                          )
                        : _c(
                            "a-tooltip",
                            { attrs: { title: "上传失败" } },
                            [
                              _c("a-icon", {
                                staticStyle: { color: "red" },
                                attrs: { type: "exclamation-circle" },
                              }),
                            ],
                            1
                          ),
                    ],
                    1
                  ),
                  [
                    _c(
                      "a-dropdown",
                      {
                        staticStyle: { "margin-left": "10px" },
                        attrs: { trigger: ["click"], placement: "bottomRight" },
                      },
                      [
                        _c(
                          "a-tooltip",
                          { attrs: { title: "操作" } },
                          [
                            file.status !== "uploading"
                              ? _c("a-icon", {
                                  staticStyle: { cursor: "pointer" },
                                  attrs: { type: "setting" },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                        _c(
                          "a-menu",
                          { attrs: { slot: "overlay" }, slot: "overlay" },
                          [
                            _vm.originColumn.allowDownload !== false
                              ? _c(
                                  "a-menu-item",
                                  {
                                    on: { click: _vm.handleClickDownloadFile },
                                  },
                                  [
                                    _c(
                                      "span",
                                      [
                                        _c("a-icon", {
                                          attrs: { type: "download" },
                                        }),
                                        _vm._v(" 下载"),
                                      ],
                                      1
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _vm.originColumn.allowRemove !== false
                              ? _c(
                                  "a-menu-item",
                                  { on: { click: _vm.handleClickDeleteFile } },
                                  [
                                    _c(
                                      "span",
                                      [
                                        _c("a-icon", {
                                          attrs: { type: "delete" },
                                        }),
                                        _vm._v(" 删除"),
                                      ],
                                      1
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _c(
                              "a-menu-item",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.handleMoreOperation(
                                      _vm.originColumn
                                    )
                                  },
                                },
                              },
                              [
                                _c(
                                  "span",
                                  [
                                    _c("a-icon", { attrs: { type: "bars" } }),
                                    _vm._v(" 更多"),
                                  ],
                                  1
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                ],
                2
              ),
            ]
          : _vm._e()
      }),
      _c(
        "a-upload",
        _vm._b(
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.hasFile,
                expression: "!hasFile",
              },
            ],
            attrs: {
              name: "file",
              data: { isup: 1 },
              multiple: false,
              action: _vm.uploadAction,
              headers: _vm.uploadHeaders,
              showUploadList: false,
            },
            on: { change: _vm.handleChangeUpload },
          },
          "a-upload",
          _vm.cellProps,
          false
        ),
        [
          _c("a-button", { attrs: { icon: "upload" } }, [
            _vm._v(_vm._s(_vm.originColumn.btnText || "上传图片")),
          ]),
        ],
        1
      ),
      _c("j-file-pop", {
        ref: "filePop",
        attrs: { number: _vm.number },
        on: { ok: _vm.handleFileSuccess },
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }